import { defineStore } from 'pinia';
import type { Customer } from '~/modules/GraphQL/types';

export interface BBRCustomerInterface extends Customer {
  custom_attributes: any;
  sap_unique_id: string;
  sap_default_id: string;
  algolia_user_public_key?: string;
  linked_accounts: string[];
  bbx_buyer: '0' | '1';
  bbx_seller: '0' | '1';
  commission_rate: string | null;
  account_name: string;
}

interface CustomerState {
  user: BBRCustomerInterface | null;
  isLoggedIn: boolean;
}

export const prefixes = ['Mr', 'Mrs', 'Ms', 'Miss'];

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false,
  }),
  getters: {
    firstName: (state) => state.user ? state.user.firstname : '',
    lastName: (state) => state.user ? state.user.lastname : '',
    fullName: (state) => state.user ? `${state.user.firstname} ${state.user.lastname}` : '',
    accountName: (state) => state.user ? state.user.account_name : '',
    email: (state) => state.user ? state.user.email : '',
    prefix: (state) => state.user ? state.user.prefix : '',
    sapUniqueID: (state) => {
      if (state.user) {
        return state.user?.sap_unique_id || '1';
      }

      return '';
    },
    sapDefaultID: (state) => {
      if (state.user) {
        return state.user?.sap_default_id || '1';
      }

      return '';
    },
    sapLinkedAccounts: (state) => {
      if (state.user) {
        return state.user?.linked_accounts || '1';
      }

      return [];
    },
  },
  actions: {
    setIsLoggedIn(isUserLoggedIn: boolean) {
      this.isLoggedIn = isUserLoggedIn;
    },
  },
});

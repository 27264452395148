













































































































import { defineComponent, ref, toRef, useContext, useRouter, watch, onBeforeUnmount } from '@nuxtjs/composition-api';
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives/';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives/';
import { SfButton } from '@storefront-ui/vue';
import {
  AisAutocomplete,
  AisConfigure,
  AisHighlight,
  AisIndex,
  AisInstantSearch,
  AisSearchBox,
} from 'vue-instantsearch';

export default defineComponent({
  name: 'AlgoliaSearchAutocomplete',
  directives: { focusTrap, clickOutside },
  components: {
    AisAutocomplete,
    AisConfigure,
    AisHighlight,
    AisIndex,
    AisInstantSearch,
    AisSearchBox,
    SfButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    hasSearchSuggestions: {
      type: Boolean,
      default: false,
    },
    hasProductSuggestions: {
      type: Boolean,
      default: false,
    },
    searchSuggestionsAmount: {
      type: Number,
      default: 6,
    },
    productSuggestionsAmount: {
      type: Number,
      default: 4,
    },
  },
  setup(props, { emit }) {
    const {
      localeRoute,
      $algoliaSearch: {
        searchClient,
        indexName,
      },
    } = useContext();
    const router = useRouter();
    const isVisible = toRef(props, 'isOpen');

    watch(isVisible, (value: boolean) => {
      document.body.classList.toggle('no-scroll', value)
    });

    onBeforeUnmount(() => {
      document.body.classList.remove('no-scroll');
    });

    const searchQuery = ref<string>('');

    const handleSubmit = () => {
      // Prevent empty search
      if (!searchQuery.value) {
        return;
      }

      // Redirect to search page
      router.push(localeRoute({
        name: 'algolia-search',
        query: {
          query: searchQuery.value,
        },
      }));

      // Close overlay
      closeModal();
    };

    const handleInput = (event) => {
      // AisSearchBox doesn't support v-model correctly,
      // so we need to update the value manually
      if (event.target.value) {
        searchQuery.value = event.target.value;
      } else  {
        searchQuery.value = '';
      }
    }

    const handleReset = () => {
      searchQuery.value = '';
    };

    const closeModal = () => {
      handleReset();
      emit('modal:close');
    }

    const onSuggestionsClick = async (searchTerm: string) => {
      searchQuery.value = searchTerm;
    }

    return {
      isVisible,
      indexName,
      searchClient,
      searchQuery,
      handleReset,
      handleSubmit,
      handleInput,
      closeModal,
      onSuggestionsClick,
    }
  },
});

import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useHybrisCart } from '~/modules/checkout/composables/useCart/useHybrisCart';
import { useCellar } from '@cellar-services/composables/useCellar';
import useUiState from '~/composables/useUiState';
import { useUiNotification } from '~/composables';

export const useHybrisUser = (error) => {
  const loading = ref(false);

  const { app } = useContext();
  const customerStore = useCustomerStore();
  const { load: loadHybrisCart } = useHybrisCart(loading, error);

  const { refreshCellar, resetCellar } = useCellar();
  const { closeAccountSwitcherModal } = useUiState();
  const { send: sendNotification } = useUiNotification();

  /**
   * Loads Hybris cart.
   * If cart is not anonymous, loads user profile too.
   * Sets both responses to its stores.
   */
  const loadCartAndUser = async (isLoggedIn = false) => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.loadCartAndUser');

        if (isLoggedIn) {
          loadHybrisCart();
        } else {
          const response = await loadHybrisCart();

          if (response.cartData.user.uid === 'anonymous') {
            return;
          }
        }

        const [{ data }, { data: accountsListData }] = await Promise.all([
          app.context.$vsf.$hybris.api.getAccount(),
          app.context.$vsf.$hybris.api.getAccountsList(),
        ]);

        const linkedAccounts = accountsListData.map(
          account => (
            {
              name: account.accountName,
              sap_unique_id: account.accountId,
              is_default: account.isDefaultAccount,
              is_active: data.customerAccount.cprAccountId === account.accountId,
            }
          )
        );

        Logger.debug('[Hybris] profile:', { data });

        customerStore.user = {
          firstname: data.customerData.firstName,
          lastname: data.customerData.lastName,
          account_name: data.customerAccount.bbrAccountName,
          algolia_user_public_key: data.algolia_user_public_key || '',
          email: data.customerData.uid,
          prefix: data.customerData.titleCode,
          sap_unique_id: data.customerAccount.cprAccountId,
          sap_default_id: data.customerAccount.cprAccountId,
          linked_accounts: linkedAccounts,
          bbx_buyer: data.bbrAccountData.bbxBidder,
          bbx_seller: data.bbrAccountData.bbxSeller,
          wishlist: {},
          wishlists: [],
          reviews: { items: [], page_info: {} },
          custom_attributes: {},
          allow_remote_shopping_assistance: false,
          commission_rate: data.bbrAccountData.commissionRate,
        };
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.loadCartAndUser', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/loadCartAndUser', err);
    } finally {
      loading.value = false;
    }
  };

  /**
   * Loads Hybris user profile.
   */
  const loadUser = async () => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.loadUser');

        const [{ data }, { data: accountsListData }] = await Promise.all([
          app.context.$vsf.$hybris.api.getAccount(),
          app.context.$vsf.$hybris.api.getAccountsList(),
        ]);

        const linkedAccounts = accountsListData.map(
          account => (
            {
              name: account.accountName,
              sap_unique_id: account.accountId,
              is_default: account.isDefaultAccount,
              is_active: data.customerAccount.cprAccountId === account.accountId,
            }
          )
        );

        Logger.debug('[Hybris] profile:', { data });

        customerStore.user = {
          firstname: data.customerData.firstName,
          lastname: data.customerData.lastName,
          account_name: data.customerAccount.bbrAccountName,
          algolia_user_public_key: data.algolia_user_public_key || '',
          email: data.customerData.uid,
          prefix: data.customerData.titleCode,
          sap_unique_id: data.customerAccount.cprAccountId,
          sap_default_id: data.customerAccount.cprAccountId,
          linked_accounts: linkedAccounts,
          bbx_buyer: data.bbrAccountData.bbxBidder,
          bbx_seller: data.bbrAccountData.bbxSeller,
          wishlist: {},
          wishlists: [],
          reviews: { items: [], page_info: {} },
          custom_attributes: {},
          allow_remote_shopping_assistance: false,
          commission_rate: data.bbrAccountData.commissionRate,
        };
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.load', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/loadUser', err);
    } finally {
      loading.value = false;
    }
  };

  /**
   * Switch between accounts under the same user login
   */
  const switchAccount = async ({ accountId, isDefault }) => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.switchAccount');

        const { data } = await app.context.$vsf.$hybris.api.switchAccount({
          bbrAccountId: accountId,
          isDefaultBBRAccount: isDefault,
        });

        Logger.debug('[Hybris] account:', { data });

        await loadCartAndUser(true);

        closeAccountSwitcherModal();
        resetCellar();
        refreshCellar();

        sendNotification({
          id: Symbol('account_switched'),
          message: app.i18n.t('You have successfully switched your account.') as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: app.i18n.t('Account switched') as string,
        });
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.switchAccount', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/switchAccount', err);
    } finally {
      loading.value = false;
    }
  };

  /**
   * Set an account as default
   */
  const setDefaultAccount = async ({ accountId }) => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.setDefaultAccount');

        await app.context.$vsf.$hybris.api.setDefaultAccount({
          bbrAccountId: accountId,
        });

        loadUser();
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.setDefaultAccount', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/setDefaultAccount', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    loadCartAndUser,
    loadUser,
    switchAccount,
    setDefaultAccount,
    loading,
  };
};
